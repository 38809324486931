import Link from 'next/link';
import styles from './style.module.scss';
import { generateBreadcrumbs } from '@/utils/common';
import menuJson from '../../../../public/json/menu.json';
import { useRouter } from 'next/router';

interface BreadCrumbProps {
  data?: any;
  addWS?: boolean;
  addHome?: boolean;
  isSetPath?: boolean;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({
  data,
  addWS = false,
  addHome = false,
  isSetPath = false,
}) => {
  const { asPath } = useRouter();
  if (isSetPath) {
    let title = asPath
      ?.replace(/\//g, '')
      ?.replace(/-/g, ' ')
      .replace(/\b\w/g, (char: string) => char?.toUpperCase());
    // ?.replace(/-and-/g, ' & ')
    if (addWS) title += ' - WoodenStreet';
    data = { breadcrumbs: [{ label: title, url: asPath }] };
  }
  const breadcrumds = Array.isArray(data?.breadcrumbs)
    ? data?.breadcrumbs
    : data?.breadcrumbs
      ? JSON.parse(data?.breadcrumbs)
      : generateBreadcrumbs(menuJson, data?.keyword);
  return (
    <div className={styles['category-nemu']}>
      <div className="container-1240">
        <ul className={styles['catgnemu-list']}>
          {(isSetPath || addHome) && (
            <li key={'home'}>
              <Link rel="preload" href={'/'}>
                Home
              </Link>
            </li>
          )}
          {breadcrumds?.map(
            (item: any, index: number) =>
              (item?.label || item?.text) && (
                <li
                  key={index}
                  className={
                    index === breadcrumds?.length - 1 ? styles['active'] : ''
                  }
                >
                  <Link
                    rel="preload"
                    href={
                      item.url ??
                      `${process.env.NEXT_PUBLIC_BASE_URL}/${item.href?.replace('https://www.woodenstreet.com', '')}`
                    }
                  >
                    {item.label ?? item.text}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default BreadCrumb;
