import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import type { GetServerSideProps } from 'next';
import Layout from '@/layout/Layout';
import Breadcrumb from '@/components/common/bread-crumb';
import { NextPageWithLayout } from '@/types/NextPageWithLayout';
import { getIsUserAuthorized } from '@/store/features/userSlice';
const HocHead = dynamic(() => import('@/components/HOC/HocHead'));
const LoginForm = dynamic(() => import('@/components/Auth/LoginForm'));

export const getServerSideProps: GetServerSideProps = async () => {
  const isAuthenticated = getCookie('isUserAuthenticated');
  if (isAuthenticated) {
    return {
      redirect: {
        permanent: true,
        destination: '/account',
      },
    };
  }
  return { props: {} };
};
const LoginPage: NextPageWithLayout = () => {
  const router = useRouter();
  const isUserAuthorized = useSelector(getIsUserAuthorized);

  if (isUserAuthorized) {
    router.push('/account');
  }

  const hocHeadData = {
    meta: {
      title: 'Register & Log in to your Account to track orders - WoodenStreet',
      description:
        'Log in to your account & get access to your order tracking, wishlist and recommendations. Keep shopping with WoodenStreet and share your desiring products with your friends.',
    },
  };

  return (
    <>
      <HocHead seo={hocHeadData} />
      <div
        className="register-accountmain"
        style={{ background: '#f7f7f8', borderBottom: '1px solid #e5e5e5' }}
      >
        <Breadcrumb
          addHome
          data={{ breadcrumbs: [{ label: 'Sign in', url: '/login' }] }}
        />
        <div className="container">
          <LoginForm isRedirect />
        </div>
      </div>
    </>
  );
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default LoginPage;
